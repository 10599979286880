import React from "react"

import Venda from "../components/Venda"
import SEO from "../components/seo"

import GlobalStyles from "../styles/global"

const HotsitePage = () => {
  return (
    <>
      <SEO
        title="Método Fan Art 3.0 | Últimas Vagas"
        description="Descubra Finalmente o Exato Método Para Aprender a Desenhar os Seus Personagens de Animes Favoritos. Mesmo Se Você Não Nasceu Com o Dom de Desenhar"
      />
      <GlobalStyles />
      <Venda />
    </>
  )
}

export default HotsitePage
