import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"
import TopAlert from "../TopAlert"
import GlobalStyles from "../../styles/global"

import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"

import * as S from "./styled"
import * as G from "../Pages/general"

import { Wifi2 } from "@styled-icons/boxicons-regular/Wifi2"
import { PlayFill } from "@styled-icons/bootstrap/PlayFill"
import { Calendar } from "@styled-icons/boxicons-regular/Calendar"
import { AccessTime } from "@styled-icons/material/AccessTime"

const Venda = () => {
  const {
    beyblade,
    dhekki,
    module01Image,
    module02Image,
    module03Image,
    module04Image,
    module05Image,
    module06Image,
    bonus01Image,
    bonus03Image,
    bonus04Image,
    mayara,
    veja,
    uol,
    folha,
    cbn,
    band,
    depoimento1,
    depoimento2,
    depoimento3,
    depoimento4,
    depoimento5,
    depoimento6,
    depoimento7,
    depoimento8,
    depoimento9,
    safeImage,
    metodoGarantiaImage,
  } = useStaticQuery(graphql`
    query {
      beyblade: file(
        relativePath: { eq: "venda/aprendendodesenhar-desenho-antigo.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dhekki: file(
        relativePath: { eq: "venda/aprendendodesenhar-desenho-dhekki.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      naruto: file(
        relativePath: { eq: "venda/aprendendodesenhar-naruto.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      module01Image: file(relativePath: { eq: "metodo-fan-art-modulo-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      module02Image: file(relativePath: { eq: "metodo-fan-art-modulo-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      module03Image: file(relativePath: { eq: "metodo-fan-art-modulo-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      module04Image: file(relativePath: { eq: "metodo-fan-art-modulo-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      module05Image: file(relativePath: { eq: "metodo-fan-art-modulo-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      module06Image: file(relativePath: { eq: "metodo-fan-art-modulo-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonus01Image: file(relativePath: { eq: "metodo-fan-art-bonus-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonus03Image: file(relativePath: { eq: "metodo-fan-art-bonus-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonus04Image: file(relativePath: { eq: "metodo-fan-art-bonus-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mayara: file(
        relativePath: { eq: "venda/aprendendodesenhar-mayara-rodrigues.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      veja: file(relativePath: { eq: "venda/veja.png" }) {
        childImageSharp {
          fluid(maxWidth: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uol: file(relativePath: { eq: "venda/uol.png" }) {
        childImageSharp {
          fluid(maxWidth: 83) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      folha: file(relativePath: { eq: "venda/folha.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cbn: file(relativePath: { eq: "venda/cbn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      band: file(relativePath: { eq: "venda/band-news.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento1: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento13.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento2: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento14.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento3: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento15.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento4: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento16.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento5: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento17.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento6: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento18.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento7: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento19.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento8: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento20.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      depoimento9: file(
        relativePath: { eq: "depoimentos/metodo-fan-art-depoimento21.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      safeImage: file(relativePath: { eq: "metodo-fan-art-seguranca.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      metodoGarantiaImage: file(
        relativePath: { eq: "metodo-fan-art-garantia-7-dias.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const options = {
    buttons: {
      backgroundColor: "rgba(30,30,36,0.8)",
      iconColor: "rgba(255, 255, 255, 0.8)",
      iconPadding: "5px",
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: "40px",
    },
  }

  return (
    <SimpleReactLightbox>
      <GlobalStyles />
      <TopAlert
        text="Não perca tempo!!! Adquira agora o Combo Ultimate X Fan Art 3.0"
        link="http://bit.ly/aprendendodesenhar-combo-ultimate-top-venda"
        txtBtn="Quero Agora"
      />
      <Header />
      <S.Headline>
        <S.Title>
          Descubra Finalmente o Exato Método Para{" "}
          <S.StrongText color="var(--yellow_logo)">
            Aprender a Desenhar
          </S.StrongText>{" "}
          os Seus Personagens de Animes Favoritos. <br /> Mesmo Se Você Não
          Nasceu Com o "Dom de Desenhar"
        </S.Title>
        <S.HeadlineBox>
          <S.HeadlineBoxText>
            <S.SubTitle>
              Você está prestes a descobrir o método que ajudou mais de 100 mil
              pessoas de todas as idades a realizarem o sonho de{" "}
              <S.StrongText color="var(--yellow_logo)">
                aprender a desenhar.
              </S.StrongText>
            </S.SubTitle>
            <S.Text>
              Ao aplicar o sistema completo do Método Fan Art 3.0 você será
              capaz de:
            </S.Text>
            <S.Text>
              Pegar uma folha, um lápis e desenhar o seu personagem favorito de
              anime em poucos minutos.
            </S.Text>
            <S.Text>
              Entender a estrutura usada pelos criadores dos personagens para
              desenhar de forma perfeita.
            </S.Text>
            <S.Text>
              Conquistar a habilidade de desenhar em poucas semanas – diferente
              de escolas tradicionais que demoram 3 anos para ter resultados.
            </S.Text>
            <S.Button
              href="http://bit.ly/aprendendodesenhar-ultima-vagas"
              target="_blank"
            >
              Quero Agora
            </S.Button>
          </S.HeadlineBoxText>
          <S.HeadlineIframe>
            <iframe
              width="100%"
              height="100%"
              title="Vídeo do instinto interior"
              src="https://www.youtube.com/embed/KXoQ_nIEYs8"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </S.HeadlineIframe>
        </S.HeadlineBox>
      </S.Headline>
      <S.Problem>
        <S.ProblemBox>
          <S.ProblemImg
            left="150px"
            fluid={beyblade.childImageSharp.fluid}
            alt="Desenho do início quando estava aprendendo a desenhar."
          />
          <S.ProblemImg
            left="50px"
            top="250px"
            fluid={dhekki.childImageSharp.fluid}
            alt="Desenho do personagem dhekki."
          />
        </S.ProblemBox>
        <S.ProblemBox display="flex">
          <S.Text>
            - Você provavelmente já tentou desenhar seu personagem preferido e o
            resultado não ficou bom?
          </S.Text>
          <S.Text>- Não nasceu com o "dom de desenhar".</S.Text>
          <S.Text>- Acredita que não tem idade para aprender.</S.Text>
          <S.Text>
            - Já tentou de tudo, até mesmo entrar em uma escola de desenho. Ou
            acha que precisa de anos para aprender.
          </S.Text>
          <S.Text>
            - E até mesmo caso você secretamente se sabote por acreditar (lá no
            fundo) que não é bom suficiente ou que nunca conseguirá aprender a
            desenhar seus personagens favoritos.
          </S.Text>
        </S.ProblemBox>
      </S.Problem>
      <S.Solution>
        <S.SolutionBox>
          <G.GeneralSubTitle>Esteja preparado para:</G.GeneralSubTitle>
          <S.Text>
            1. Desenhar na frente dos seus amigos e familiares, mostrando a sua
            nova habilidade artística.
          </S.Text>
          <S.Text>
            2. Desenhar sem precisar copiar por cima, apenas olhando conseguirá
            desenhar qualquer personagem.
          </S.Text>
          <S.Text>
            3. Recriar no papel todas aquelas cenas e os personagens mais
            marcantes para você.
          </S.Text>
        </S.SolutionBox>
        <S.SolutionWrapper>
          <G.GeneralSubTitle>Como o método FanArt funciona?</G.GeneralSubTitle>
          <G.GeneralSection>
            <G.GeneralBox shadow>
              <G.GeneralIcon color="#70ca57">
                <Wifi2 />
              </G.GeneralIcon>
              <G.GeneralText>
                Curso 100% Online Assista a qualquer hora em qualquer lugar,
                seja no computador ou celular.
              </G.GeneralText>
            </G.GeneralBox>
            <G.GeneralBox shadow>
              <G.GeneralIcon color="#1fa1df">
                <PlayFill />
              </G.GeneralIcon>
              <G.GeneralText>
                +35 Aulas Com foco no seu aprendizado, práticas, objetivas e com
                exercícios.
              </G.GeneralText>
            </G.GeneralBox>
            <G.GeneralBox shadow>
              <G.GeneralIcon color="#faab4e">
                <Calendar />
              </G.GeneralIcon>
              <G.GeneralText>
                Acesso Vitalício. Pague uma vez, acesse para sempre e quantas
                vezes quiser.
              </G.GeneralText>
            </G.GeneralBox>
            <G.GeneralBox shadow>
              <G.GeneralIcon color="#de5aa5">
                <AccessTime />
              </G.GeneralIcon>
              <G.GeneralText>
                Liberado 24h/dia Acesse a hora que quiser, total liberdade, no
                seu tempo.
              </G.GeneralText>
            </G.GeneralBox>
          </G.GeneralSection>
        </S.SolutionWrapper>
      </S.Solution>
      <S.SolutionModule>
        <G.GeneralSubTitle>
          <S.StrongText color="var(--orange_logo)">
            O que você vai aprender{" "}
          </S.StrongText>
          neste treinamento?
        </G.GeneralSubTitle>
        <S.SolutionModuleSection>
          <S.SolutionModuleBox>
            <S.SolutionModuleTitle>
              Módulo 1 - Análise de Imagens
            </S.SolutionModuleTitle>
            <S.SolutionModuleImg
              fluid={module01Image.childImageSharp.fluid}
              alt="Imagem com o módulo 1 do curso, mostrando o painel do curso."
            />
            <S.SolutionModuleTextTitle>
              Você começa aqui...
            </S.SolutionModuleTextTitle>
            <S.SolutionModuleText>
              Nesse módulo você vai aprender como analisar as imagens de um modo
              diferente das pessoas normais. <br /> Evitando os detalhes que
              acabam passando despercebido e alinhando passo-a-passo os traços
              que fazem a diferença entre um desenho profissional e amador.
            </S.SolutionModuleText>
          </S.SolutionModuleBox>
          <S.SolutionModuleBox>
            <S.SolutionModuleTitle>
              Módulo 2 - Análise de Rostos
            </S.SolutionModuleTitle>
            <S.SolutionModuleImg
              fluid={module02Image.childImageSharp.fluid}
              alt="Imagem com o módulo 2 do curso, mostrando o painel do curso."
            />
            <S.SolutionModuleTextTitle>
              Desenhando o rosto perfeito...
            </S.SolutionModuleTextTitle>
            <S.SolutionModuleText>
              Você provavelmente já sofreu tentando desenhar um personagem em
              posições diferentes. Isso é um desafio comum até mesmo pra
              desenhistas intermediários. <br />
              Vou mostrar exatamente como você pode variar as posições dos seus
              personagens favoritos mantendo a proporção e simetria do desenho.
            </S.SolutionModuleText>
          </S.SolutionModuleBox>
          <S.SolutionModuleBox>
            <S.SolutionModuleTitle>
              Módulo 3 - Variação de Ângulos
            </S.SolutionModuleTitle>
            <S.SolutionModuleImg
              fluid={module03Image.childImageSharp.fluid}
              alt="Imagem com o módulo 3 do curso, mostrando o painel do curso."
            />
            <S.SolutionModuleTextTitle>
              Variando posições...
            </S.SolutionModuleTextTitle>
            <S.SolutionModuleText>
              Nesse módulo eu vou te ensinar passo-a-passo como desenhar rostos
              como um profissional. Existem detalhes que somente um profissional
              pode te orientar. <br />
              Esse é um dos maiores desafios pra quem começa a desenhar seus
              primeiros personagens.
            </S.SolutionModuleText>
          </S.SolutionModuleBox>
        </S.SolutionModuleSection>
        <S.SolutionModuleSection>
          <S.SolutionModuleBox>
            <S.SolutionModuleTitle>
              Módulo 4 - Anatomia do Corpo
            </S.SolutionModuleTitle>
            <S.SolutionModuleImg
              fluid={module04Image.childImageSharp.fluid}
              alt="Imagem com o módulo 4 do curso, mostrando o painel do curso."
            />
            <S.SolutionModuleTextTitle>
              As regras da proporção perfeita...
            </S.SolutionModuleTextTitle>
            <S.SolutionModuleText>
              Sabe quando você trava na hora de fazer a proporção de tamanho de
              um desenho? Isso vai acabar definitivamente. <br />
              Além da diferença de tamanho entre personagens maiores/maiores e
              as demais diferenças de idade dos personagens que acabam
              influenciando em seu tamanho. <br />
              Esse módulo é ESSENCIAL para se desenhar com perfeição.
            </S.SolutionModuleText>
          </S.SolutionModuleBox>
          <S.SolutionModuleBox>
            <S.SolutionModuleTitle>Módulo 5 - Cabelos</S.SolutionModuleTitle>
            <S.SolutionModuleImg
              fluid={module05Image.childImageSharp.fluid}
              alt="Imagem com o módulo 5 do curso, mostrando o painel do curso."
            />
            <S.SolutionModuleTextTitle>
              Como fazer cabelos de anime/mangá...
            </S.SolutionModuleTextTitle>
            <S.SolutionModuleText>
              Quase todo mundo que já tentou desenhar um anime ou mangá já
              travou na hora de fazer os cabelos. Parece que eles nunca ficam
              exatamente como os desenhos originais. <br />E nesse módulo você
              vai saber a exata técnica para fazer cabelos perfeitos do seu
              anime ou mangá favorito.
            </S.SolutionModuleText>
          </S.SolutionModuleBox>
          <S.SolutionModuleBox>
            <S.SolutionModuleTitle>Módulo 6 - Mãos e pés</S.SolutionModuleTitle>
            <S.SolutionModuleImg
              fluid={module06Image.childImageSharp.fluid}
              alt="Imagem com o módulo 6 do curso, mostrando o painel do curso."
            />
            <S.SolutionModuleTextTitle>
              Mãos e pés perfeitos do seu personagem...
            </S.SolutionModuleTextTitle>
            <S.SolutionModuleText>
              Não adianta seu desenho ficar perfeito se você não souber
              finalizar as mãos e os pés dos personagens. <br />
              Isso muitas vezes é o detalhe que falta para que todos admirem
              mais o seu desenho e também o que separa alguém que desenha bem de
              alguém que apenas faz "rostos bem feitos". <br />
              Nesse módulo você vai ter o toque final para deixar seus
              personagens perfeitos.
            </S.SolutionModuleText>
          </S.SolutionModuleBox>
        </S.SolutionModuleSection>
      </S.SolutionModule>
      <S.Credentials>
        <S.CredentialsWrapper>
          <S.CredentialsImg
            fluid={mayara.childImageSharp.fluid}
            alt="Foto da Mayara Rodrigues, professora do método fan art."
          />
          <S.CredentialsBoxText>
            <S.CredentialsTitle>Quem é a professora Mayara?</S.CredentialsTitle>
            <S.CredentialsText>
              Hoje, com{" "}
              <S.StrongText color="var(--orange_logo_hover)">
                +100 mil alunos
              </S.StrongText>{" "}
              no Brasil e no mundo e{" "}
              <S.StrongText color="var(--orange_logo_hover)">
                +1 milhão de inscritos no Youtube.
              </S.StrongText>{" "}
              Mayara, descobriu que sua missão é reacender este sonho que muitas
              pessoas tinham e torná-lo uma realidade. <br />
            </S.CredentialsText>
            <S.CredentialsText>
              Depois de anos, Mayara teve a nítida perceção de que métodos
              tradicionais de ensino causavam frustração e desistência. <br />{" "}
              Foi então que a sua jornada para desenvolver um método realmente
              inovador e inspirador começou. <br />
            </S.CredentialsText>
            <S.CredentialsText>
              Focada em sua missão de ajudar mais de{" "}
              <S.StrongText color="var(--orange_logo_hover)">
                200 mil pessoas até 2025
              </S.StrongText>{" "}
              a realizar este antigo sonho que é aprender a desenhar, mesmo se
              não nasceu com o "dom de desenhar". Surgiu assim o{" "}
              <S.StrongText color="var(--orange_logo_hover)">
                Método Fan Art 3.0
              </S.StrongText>
            </S.CredentialsText>
            <S.CredentialSign>Mayara Rodrigues</S.CredentialSign>
          </S.CredentialsBoxText>
        </S.CredentialsWrapper>
        <S.CredentialsSubTitle>
          Mayara Rodrigues em algumas mídias:
        </S.CredentialsSubTitle>
        <S.CredentialWrapperNoticias>
          <S.CredentialsNoticiasImg
            width="75px"
            height="36px"
            fluid={veja.childImageSharp.fluid}
            alt="Logotipo da veja."
          />
          <S.CredentialsNoticiasImg
            width="83px"
            height="28px"
            fluid={uol.childImageSharp.fluid}
            alt="Logotipo da uol."
          />
          <S.CredentialsNoticiasImg
            width="146px"
            height="24px"
            fluid={folha.childImageSharp.fluid}
            alt="Logotipo Folha de São Paulo."
          />
          <S.CredentialsNoticiasImg
            width="61px"
            height="20px"
            fluid={cbn.childImageSharp.fluid}
            alt="Logotipo da CBN."
          />
          <S.CredentialsNoticiasImg
            width="61px"
            height="25px"
            fluid={band.childImageSharp.fluid}
            alt="Logotipo da BandNews."
          />
        </S.CredentialWrapperNoticias>
      </S.Credentials>
      <S.Bonus>
        <S.BonusTitle>
          Além do curso completo, eu vou disponibilizar mais{" "}
          <S.StrongText color="var(--facebook_hover)">3 bônus</S.StrongText>
        </S.BonusTitle>
        <S.BonusText>
          Aqui estão os bônus que vou disponibilizar para você ao garantir sua
          vaga hoje no Método Fan Art 3.0
        </S.BonusText>
        <S.BonusBox>
          <S.BonusBoxImg>
            <S.BonusImg
              fluid={bonus01Image.childImageSharp.fluid}
              alt="Imagem de bônus de melhoria de traço"
            />
          </S.BonusBoxImg>
          <S.BonusBoxText>
            <S.BonusSubTitle>
              Bônus #01 – Como Melhorar O Seu Traço
            </S.BonusSubTitle>
            <S.BonusTextInside>
              Uma aula completa com a Mayara para direcionar ainda mais a sua
              evolução no desenho de animes. Nele, você aprenderá:
            </S.BonusTextInside>
            <S.BonusTextInside>
              Como Melhorar o Seu Traço em poucos minutos
            </S.BonusTextInside>
            <S.BonusTextInside>
              Os maiores erros de quem está começando e como evitar
            </S.BonusTextInside>
            <S.BonusTextInside>
              Como saber se você está evoluindo o seu traço?
            </S.BonusTextInside>
            <S.BonusTextInsideRight>
              Vendido separadamente por:{" "}
              <S.StrongText color="var(--orange_logo)">R$127,00</S.StrongText>
            </S.BonusTextInsideRight>
          </S.BonusBoxText>
        </S.BonusBox>
        <S.BonusBox>
          <S.BonusBoxImg>
            <S.BonusImg
              fluid={bonus03Image.childImageSharp.fluid}
              alt="Imagem de bônus de grupo exclusivo para alunos"
            />
          </S.BonusBoxImg>
          <S.BonusBoxText>
            <S.BonusSubTitle>
              Bônus #02 – Acesso a Grupo Exclusivo de Alunos
            </S.BonusSubTitle>
            <S.BonusTextInside>
              Você não está sozinho, você poderá evoluir junto com mais de
              85.000 alunos. Nele, você poderá:
            </S.BonusTextInside>
            <S.BonusTextInside>
              Tirar Dúvidas diretamente com a Mayara Rodrigues
            </S.BonusTextInside>
            <S.BonusTextInside>
              Participar de sorteios e ganhar descontos exclusivos em outros
              cursos
            </S.BonusTextInside>
            <S.BonusTextInside>
              Interagir, conversar e acompanhar a evolução de outros alunos;
            </S.BonusTextInside>
            <S.BonusTextInsideRight>
              Vendido separadamente por:{" "}
              <S.StrongText color="var(--orange_logo)">
                Não Tem Preço
              </S.StrongText>
            </S.BonusTextInsideRight>
          </S.BonusBoxText>
        </S.BonusBox>
        <S.BonusBox>
          <S.BonusBoxImg>
            <S.BonusImg
              fluid={bonus04Image.childImageSharp.fluid}
              alt="Imagem de bônus de grupo exclusivo para alunos"
            />
          </S.BonusBoxImg>
          <S.BonusBoxText>
            <S.BonusSubTitle>
              Bônus #03 – Certificado de Conclusão
            </S.BonusSubTitle>
            <S.BonusTextInside>
              Você não irá apenas aprender a desenhar os seus personagens
              favoritos de anime. Ao finalizar o curso, você receberá o seu
              certificado de conclusão de curso que poderá se apresentado e
              usado onde você quiser.
            </S.BonusTextInside>
            <S.BonusTextInside>
              Será o seu símbolo de que conseguiu completar a sua jornada e
              realizar o seu sonho.
            </S.BonusTextInside>
            <S.BonusTextInsideRight>
              Vendido separadamente por:{" "}
              <S.StrongText color="var(--orange_logo)">
                Não Tem Preço
              </S.StrongText>
            </S.BonusTextInsideRight>
          </S.BonusBoxText>
        </S.BonusBox>
        <S.BonusButton
          href="http://bit.ly/aprendendodesenhar-ultima-vagas"
          target="_blank"
        >
          Adquirir agora o Método Fan Art3.0 e garantir meus bônus
        </S.BonusButton>
      </S.Bonus>
      <S.Depoimentos>
        <S.DepoimentosTitle>
          Pessoas Reais De Todas As Idades Com{" "}
          <S.StrongText color="var(--pink_logo_hover)">
            Resultados Incríveis
          </S.StrongText>
        </S.DepoimentosTitle>
        <S.DepoimentosText>
          São mais de 100.275 Alunos em toda nossa história!
        </S.DepoimentosText>
        <S.DepoimentosWrapper>
          <SRLWrapper options={options}>
            <G.GeneralGaleria>
              <G.GeneralImagePequena
                shadow
                fluid={depoimento1.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com um desenho do Deidara"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento2.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com desenho do Naruto"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento3.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com desenho do Naruto"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento4.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com rascunho de uma garota"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento5.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com alguns exemplos"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento6.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com v'rios olhos e rascunhos de personagens"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento7.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com um desenho do naruto e sasuke"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento8.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com vários desenhos do rosto de naruto"
              />
              <G.GeneralImagePequena
                shadow
                fluid={depoimento9.childImageSharp.fluid}
                alt="Depoimento dos alunos sobre o curso com o desenho do kamus de aquario"
              />
            </G.GeneralGaleria>
          </SRLWrapper>
        </S.DepoimentosWrapper>
      </S.Depoimentos>
      <S.Oferta>
        <S.OfertaTitle>
          Essa oferta é por{" "}
          <S.StrongText color="var(--orange_logo_hover)">
            tempo limitado.
          </S.StrongText>
          <br />
          Você nunca mais verá esse preço
        </S.OfertaTitle>
        <S.OfertaBoxText>
          <S.OfertaLine>
            <S.OfertaText>Curso Completo Método Fan Art</S.OfertaText>
            <G.GeneralTextThroughLine size="1.2rem">
              R$297,00
            </G.GeneralTextThroughLine>
          </S.OfertaLine>
          <S.OfertaLine>
            <S.OfertaText>
              Bônus Exclusivo: Como Melhorar o Seu Traço
            </S.OfertaText>
            <G.GeneralTextThroughLine size="1.2rem">
              R$127,00
            </G.GeneralTextThroughLine>
          </S.OfertaLine>
          <S.OfertaLine>
            <S.OfertaText>Atualizações de Novas Aulas</S.OfertaText>
            <G.GeneralTextThroughLine size="1.2rem">
              R$227,00
            </G.GeneralTextThroughLine>
          </S.OfertaLine>
          <S.OfertaLine>
            <S.OfertaText>Desconto Exclusivo Em Outros Cursos</S.OfertaText>
            <G.GeneralTextThroughLine size="1.2rem">
              Não tem preço
            </G.GeneralTextThroughLine>
          </S.OfertaLine>
          <S.OfertaLine>
            <S.OfertaText>
              Bônus Exclusivo: Acesso ao Grupo Exclusivo de Alunos
            </S.OfertaText>
            <G.GeneralTextThroughLine size="1.2rem">
              Não tem preço
            </G.GeneralTextThroughLine>
          </S.OfertaLine>
          <S.OfertaLine>
            <S.OfertaText>
              Bônus Exclusivo: Certificado de Conclusão
            </S.OfertaText>
            <G.GeneralTextThroughLine size="1.2rem">
              Não tem preço
            </G.GeneralTextThroughLine>
          </S.OfertaLine>
        </S.OfertaBoxText>
        <S.OfertaTextExterno>Tudo isso deveria custa:</S.OfertaTextExterno>
        <G.GeneralTextThroughLine size="4rem">
          R$657,00
        </G.GeneralTextThroughLine>
        <S.OfertaTextExterno>
          Porém, por{" "}
          <S.StrongText color="var(--orange_logo_hover)">
            Tempo Limitado,
          </S.StrongText>{" "}
          você poderá fazer a inscrição por valor especial de lançamento, por
          apenas:
        </S.OfertaTextExterno>
        <S.OfertaBoxPreco>
          <S.OfertaText>
            Por apenas <S.StrongText>R$97,00</S.StrongText> ou
          </S.OfertaText>
          <S.OfertaPreco size="4rem">10x</S.OfertaPreco>{" "}
          <S.OfertaPreco size="10rem">11</S.OfertaPreco>
          <S.OfertaPreco size="4rem">,08</S.OfertaPreco>
          <S.OfertaContest>
            Menos de R$ 1 por dia e tenha acesso a poderosas ferramentas e
            conteúdos para aprender a desenhar seus personagens de anime
            favoritos em tempo recorde.
          </S.OfertaContest>
        </S.OfertaBoxPreco>
        <S.OfertaButton
          href="http://bit.ly/aprendendodesenhar-ultima-vagas"
          target="_blank"
        >
          Sim, eu quero aprender a desenhar!
        </S.OfertaButton>
        <S.OfertaSafeImgBack>
          <S.OfertaSafeImg
            fluid={safeImage.childImageSharp.fluid}
            alt="Imagem com os selos de segurança do site, compra segura, satisfação garantida e privacidade protegida"
          />
        </S.OfertaSafeImgBack>
      </S.Oferta>
      <S.Garantia>
        <G.GeneralImagePequena
          fluid={metodoGarantiaImage.childImageSharp.fluid}
          alt="Imagem de garantia, 7 dias de garantias incondicional"
        />
        <S.GarantiaTextBox>
          <S.GarantiaTitle>Garantia Incondicional de 7 Dias</S.GarantiaTitle>
          <S.GarantiaText>
            <S.StrongText color="var(--black_pure)">
              Fique 100% Satisfeito Ou Peça Seu Dinheiro De Volta.
            </S.StrongText>
          </S.GarantiaText>
          <S.GarantiaText>
            O "Método Fan Art 3.0" conta com uma garantia 100% de satisfação. Ou
            seja, você tem 7 dias para acessar o conteúdo e se ainda achar que o
            curso não é para você, basta pedir reembolso e devolverei seu
            dinheiro de forma integral. Sem perguntas e questionamentos. Neste
            caso, os riscos serão todos meus.
          </S.GarantiaText>
        </S.GarantiaTextBox>
      </S.Garantia>
      <S.Objecao>
        <S.ObjecaoBox>
          <S.ObjecatoTitle>
            Alguma Dúvida? A Resposta para Todas as Perguntas Frequentes Abaixo
            é SIM!
          </S.ObjecatoTitle>
          <S.ObjecaoText>
            - É seguro comprar o curso Método Fan Art 3.0?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - Você realmente devolve o dinheiro caso eu não goste do curso?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - Posso parcelar em até 10x no cartão de crédito?
          </S.ObjecaoText>
          <S.ObjecaoText>- Posso pagar em 2 cartões diferentes?</S.ObjecaoText>
          <S.ObjecaoText>
            - Terei acesso imediato à área exclusiva de aluno(a) do curso?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - Ganharei acesso as aulas em vídeo + audio + texto?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - Ao finalizar minha compra ganho acesso imediato ao Grupo de
            Alunos?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - Ao terminar o curso terei acesso ao meu certificado?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - É verdade que já consigo ver meu resultado ou do meu filho(a) já
            nas primeiras semanas?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - Eu sinto que não consigo aprender ou que não tenho o dom para
            desenhar. Seu curso vai me ensinar mesmo assim a desenhar meus
            personagens favoritos?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - As técnicas ensinadas no curso servem para meu filho(a) que tem
            entre 8 e 16 anos?
          </S.ObjecaoText>
          <S.ObjecaoText>
            - As técnicas ensinadas servem para mim que já sou velho e sinto que
            passei da idade de aprender a desenhar?
          </S.ObjecaoText>
          <S.ObjecatoTitle>
            - Siga os 3 passos simples abaixo para garantir sua vaga no Método
            Fan Art 3.0:
          </S.ObjecatoTitle>
          <S.ObjecaoText>
            - Seu acesso imediato ao curso Método Fan Art 3.0 está a 3 simples
            passos de você.
          </S.ObjecaoText>
          <br />
          <S.ObjecaoText>Tudo o que você precisa fazer é</S.ObjecaoText>
          <br />
          <S.ObjecaoText>1. Clicar no botão abaixo:</S.ObjecaoText>
          <S.ObjecaoText>
            2. Preencher as informações de pagamento 100% seguro e garantido com
            encriptação de dados.
          </S.ObjecaoText>
          <S.ObjecaoText>
            3. Receber nosso email imediato com os dados de login e senha para
            seu acesso.
          </S.ObjecaoText>
          <S.ObjecaoText>
            <S.StrongText color="var(--black_pure)">
              Siga o passo #1 abaixo:
            </S.StrongText>
          </S.ObjecaoText>
        </S.ObjecaoBox>
        <S.ObjecaoButton
          href="http://bit.ly/aprendendodesenhar-ultima-vagas"
          target="_blank"
        >
          Sim, Eu quero aprender a desenhar!
        </S.ObjecaoButton>
      </S.Objecao>
      <Footer />
    </SimpleReactLightbox>
  )
}

export default Venda
