import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"

export const Headline = styled.section`
  width: 100%;
  padding: 5rem 2rem;
  background: url("/img/aprendendodesenhar-venda-back-blue.jpg") center top;
  background-color: var(--blue_logo_hover);
  color: var(--white);

  ${media.lessThan("large")`
    padding: 5rem 1rem;
    background-image: none;
  `}
`

export const StrongText = styled.strong`
  font-weight: 700;
  color: ${props => (props.color ? props.color : "var(--white)")};
`

export const Title = styled.h1`
  width: 70%;
  margin: 2rem auto;
  line-height: 1.3;
  font-size: 2.5rem;
  text-align: center;

  ${media.lessThan("large")`
    padding-top: 5rem;
    font-size: 2rem;
    margin: 1rem auto;
    width: 100%;
  `}
`

export const SubTitle = styled.h2`
  margin: 2rem auto;
  width: 90%;
  line-height: 1.4;
  font-size: 1.6rem;
  padding: 0 2rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 0 1rem;
  `}
`

export const Text = styled.p`
  margin: 2rem auto;
  width: 90%;
  line-height: 1.4;
  font-size: 1.2rem;
  padding: 0 2rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 0 1rem;
  `}
`

export const Button = styled.a`
  display: block;
  width: 30%;
  text-align: center;
  border-radius: 2rem;
  padding: 1rem;
  color: var(--white);
  background-color: var(--alert_button);
  margin: 0 auto;
  font-size: 1.25rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s;
  cursor: pointer;
  box-shadow: 3px 3px 100px 10px var(--black_pure);

  &:hover {
    background: var(--alert_hover);
  }

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const HeadlineBox = styled.div`
  display: flex;
  width: 100%;
  padding: 5rem;

  ${media.lessThan("large")`
    padding: 1rem;
    flex-direction: column;
  `}
`

export const HeadlineBoxText = styled.div`
  width: 50%;
  padding: 0 2rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;
  `}
`

export const HeadlineIframe = styled.div`
  width: 50%;
  background-color: var(--black_pure);
  padding: 0.25rem;
  height: 515px;
  box-shadow: 3px 3px 100px 10px var(--text_color);

  ${media.lessThan("large")`
    width: 100%;
    height: 350px
  `}
`

export const Problem = styled.section`
  width: 100%;
  padding: 5rem 2rem;
  background-color: var(--orange_logo_hover);
  color: var(--white);
  display: flex;

  ${media.lessThan("large")`
    padding: 5rem 1rem;
    flex-direction: column;
  `}
`
export const ProblemBox = styled.div`
  display: ${props => (props.display ? props.display : "block")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const ProblemImg = styled(Img)`
  float: left;
  width: 400px;
  position: relative;
  top: ${props => (props.top ? props.top : "0")};
  left: ${props => (props.left ? props.left : "0")};
  box-shadow: 3px 3px 100px 10px var(--text_color);

  ${media.lessThan("large")`
    width: 100%;
    top: 0;
    left: 0;
  `}
`

export const Solution = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--black_pure);
  padding: 5rem 2rem;
  color: var(--white);

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.2;
    background: url("/img/aprendendodesenhar-venda-back.jpg") center top;
    background-size: cover;
  }

  ${media.lessThan("large")`
    padding: 5rem 1rem;
    background-position: left;
  `}
`
export const SolutionWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 2rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`

export const SolutionBox = styled.div`
  display: ${props => (props.display ? props.display : "block")};
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  width: 50%;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const SolutionImg = styled(Img)`
  width: 600px;
  margin: 0 auto;
  box-shadow: 3px 3px 100px 10px rgba(255, 255, 255, 0.25);
`

export const SolutionModule = styled.section`
  width: 100%;
  padding: 5rem 2rem;
  background-color: var(--blue_logo);
  color: var(--white);
`

export const SolutionModuleSection = styled.div`
  display: flex;
  width: 75%;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
    flex-direction: column;
  `}
`

export const SolutionModuleBox = styled.div`
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 1px 10px 1px var(--white);
  width: 33%;
  margin: 1.5rem;
  padding: 2rem;
  border-radius: 1.5rem;

  ${media.lessThan("large")`
    width: 100%;
    margin: 1rem 0;
  `}
`

export const SolutionModuleTitle = styled.h3`
  text-align: center;
  background: var(--orange_logo);
  color: var(--white);
  font-weight: 700;
  padding: 1rem;
`

export const SolutionModuleTextTitle = styled.p`
  color: var(--text_color);
  font-size: 1.2rem;
  text-align: center;
  font-weight: 700;
  margin: 1rem;
`

export const SolutionModuleText = styled.p`
  color: var(--text_color);
  line-height: 1.6;
`

export const SolutionModuleImg = styled(Img)`
  margin: 1rem 0;
  width: 100%;
  border-radius: 0.8rem;
`

export const Credentials = styled.section`
  width: 100%;
  padding: 5rem 2rem;
  background: url("/img/aprendendodesenhar-venda-mayara-back.jpg") center center;
  background-color: var(--white);
  color: var(--black_pure);

  ${media.lessThan("large")`
    padding: 5rem 1rem;
    background-image: none;
  `}
`

export const CredentialsWrapper = styled.div`
  display: flex;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const CredentialsImg = styled(Img)`
  border-radius: 0.8rem;
  width: 450px;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 2rem;
  `}
`

export const CredentialsBoxText = styled.div`
  padding: 0 2rem;
  width: 50%;

  ${media.lessThan("large")`
    width: 100%;
    padding: 0 1rem;
  `}
`

export const CredentialsSubTitle = styled.h2`
  font-size: 1.2rem;
  margin: 6rem auto 2rem;
  text-transform: uppercase;
  text-align: center;
`

export const CredentialsTitle = styled.h2`
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
`

export const CredentialsText = styled.p`
  width: 70%;
  margin: 1rem 0;
  line-height: 1.6;
  font-size: 1.2rem;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const CredentialSign = styled.p`
  font-family: "Rock Salt", cursive;
  font-size: 1.4rem;
`

export const CredentialWrapperNoticias = styled.div`
  width: 70%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 2rem 3rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
    flex-direction: column;
  `}
`

export const CredentialsNoticiasImg = styled(Img)`
  width: ${props => (props.width ? props.width : "150px")};
  height: ${props => (props.height ? props.height : "150px")};

  ${media.lessThan("large")`
    margin: 0.5rem auto;
  `}
`

export const Bonus = styled.section`
  width: 100%;
  padding: 5rem 2rem;
  background-color: var(--blue_logo);
  object-fit: contain;
  color: var(--white);

  ${media.lessThan("large")`
    padding: 5rem 1rem;
  `}
`

export const BonusTitle = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  margin: 2rem auto;
`

export const BonusText = styled.p`
  text-align: center;
  font-size: 1.2rem;
`

export const BonusBox = styled.div`
  background-color: var(--facebook_hover);
  display: flex;
  border-radius: 0.8rem;
  padding: 2rem;
  width: 70%;
  margin: 2rem auto 0;

  ${media.lessThan("large")`
    width: 100%;
    flex-direction: column;
    padding: 1rem;
  `}
`
export const BonusBoxImg = styled.div`
  width: 50%;

  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 1.5rem;
  `}
`
export const BonusImg = styled(Img)`
  width: 450px;
  margin: 0 auto;

  ${media.lessThan("large")`
    width: 100%;
  `}
`
export const BonusBoxText = styled.div`
  width: 50%;
  padding: 0 2rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 0 1rem;
  `}
`

export const BonusSubTitle = styled.h3`
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`

export const BonusTextInsideRight = styled.p`
  text-align: right;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 1.5rem;
`
export const BonusTextInside = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
`

export const BonusButton = styled.a`
  display: block;
  width: 30%;
  text-align: center;
  border-radius: 0.8rem;
  padding: 1rem;
  color: var(--white);
  background-color: var(--alert_button);
  margin: 5rem auto 0;
  line-height: 1.4;
  font-size: 1.25rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 0.3s;
  cursor: pointer;
  box-shadow: 1px 1px 30px 1px var(--black_pure);

  &:hover {
    background: var(--alert_hover);
  }

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const Depoimentos = styled.section`
  width: 100%;
  padding: 5rem 2rem;
  background-color: var(--white);
  object-fit: contain;
  color: var(--black_pure);

  ${media.lessThan("large")`
    padding: 5rem 1rem;
  `}
`
export const DepoimentosTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--pink_logo);
  text-align: center;
  margin-bottom: 2rem;
`

export const DepoimentosText = styled.p`
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.5rem;
  text-shadow: 3px 3px 10px var(--black_pure);
`

export const DepoimentosWrapper = styled.div`
  margin: 0 auto;
  width: 70%;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const Oferta = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0;
  background-color: var(--black_pure);
  color: var(--white);
  text-align: center;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.1;
    background: url("/img/aprendendodesenhar-venda-back3.jpg") center top;
    background-size: cover;
  }
`

export const OfertaTitle = styled.h2`
  font-size: 2.2rem;
  margin: 2rem;
`

export const OfertaBoxText = styled.div`
  padding: 2rem;
  border-radius: 0.8rem;
  background-color: var(--white_transparent);
  width: 50%;
  margin: 2rem auto;

  ${media.lessThan("large")`
    width: 95%;
    padding: 1rem;
  `}
`

export const OfertaLine = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--white_transparent);
  padding: 0.5rem;

  ${media.lessThan("large")`
    padding: .5rem 0;
  `}
`

export const OfertaText = styled.p`
  font-size: 1.2rem;
`

export const OfertaTextExterno = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin: 1.5rem;
`

export const OfertaBoxPreco = styled.div`
  width: 45%;
  margin: 3rem auto;
  background-color: var(--orange_logo);
  padding: 2rem;
  z-index: 10;
  border-radius: 0.8rem;

  ${media.lessThan("large")`
    width: 95%;
  `}
`

export const OfertaPreco = styled.span`
  font-size: ${props => (props.size ? props.size : "1.2rem")};
  color: var(--orange_logo_hover);
  font-weight: 700;
  margin: 2rem 0;
  z-index: 10;
`

export const OfertaContest = styled.p`
  width: 50%;
  margin: 0 auto 3rem;
  line-height: 1.4;

  ${media.lessThan("large")`
    width: 85%;
  `}
`

export const OfertaButton = styled.a`
  display: block;
  width: 45%;
  text-align: center;
  border-radius: 5rem;
  padding: 1rem;
  color: var(--white);
  background-color: var(--yellow_logo);
  margin: 5rem auto 0;
  line-height: 1.4;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  transition: transform 0.2s ease 0s;
  cursor: pointer;
  box-shadow: 1px 1px 30px 1px var(--yellow_logo);
  position: relative;
  top: -120px;
  z-index: 10;

  &:hover {
    transform: scale(1.25);
  }

  ${media.lessThan("large")`
    width: 100%;
    font-size: 1.25rem;
  `}
`

export const OfertaSafeImgBack = styled.div`
  background-color: var(--white);
  padding: 3rem;
`
export const OfertaSafeImg = styled(Img)`
  margin: 0 auto;
  width: 500px;
  z-index: 10;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const Garantia = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0;
  background: url("/img/aprendendodesenhar-venda-mayara-back.jpg") center top;
  color: var(--black_pure);
  text-align: center;
  display: flex;
  justify-content: center;

  ${media.lessThan("large")`
    flex-direction: column;
    padding-bottom: 1.5rem;
  `}
`

export const GarantiaTextBox = styled.div`
  width: 40%;

  ${media.lessThan("large")`
    width: 90%;
    margin: 0 auto;
  `}
`

export const GarantiaTitle = styled.h2`
  font-size: 2.2rem;
  margin: 2rem;
  font-weight: 700;
  text-transform: uppercase;
`

export const GarantiaText = styled.p`
  line-height: 1.4;
  font-size: 1.2rem;
  text-align: right;

  ${media.lessThan("large")`
    text-align: center;
  `}
`

export const Objecao = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0;
  background-color: var(--white);
  color: var(--black_pure);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan("large")`
    padding-top: 1.5rem;
  `}
`

export const ObjecaoBox = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: left;

  ${media.lessThan("large")`
    width: 90%;
  `}
`

export const ObjecatoTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 2rem;

  ${media.lessThan("large")`
    font-size: 1.5rem;
    margin: 1rem;
  `}
`

export const ObjecaoText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
`

export const ObjecaoButton = styled.a`
  display: block;
  width: 45%;
  text-align: center;
  border-radius: 5rem;
  padding: 1rem;
  color: var(--white);
  background-color: var(--alert_button);
  margin: 5rem auto 0;
  line-height: 1.4;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  transition: transform 0.2s ease 0s;
  cursor: pointer;
  box-shadow: 1px 1px 30px 1px var(--alert_button);
  position: relative;

  &:hover {
    transform: scale(1.25);
    background-color: var(--alert_hover);
  }

  ${media.lessThan("large")`
    width: 95%;
    font-size: 1.2rem;
  `}
`
